<template>
  <section class="hero is-fullheight">
    <ModalAddAdminUser
      v-if="activeModalAddAdminUser"
      :active="activeModalAddAdminUser"
      @close-modal="$emit('close-modal')"
      :successfulCreationUser="successfulCreationUser"
    />
    <ModalEditAdminUser
      v-if="activeModalEditAdminUser"
      :active="activeModalEditAdminUser"
      :adminDataSelected="adminDataSelected"
      @close-modal="activeModalEditAdminUser = false"
      :successfullEditUser="successfullEditUser"
    />
    <div class="hero-body">
      <div class="container">
        <nav class="level">
          <div class="level-left" />
          <!-- Right side -->
          <div class="level-right">
            <div class="level-item">
              <div class="field control has-icons-right">
                <input
                  class="input input_reset"
                  type="text"
                  placeholder="Buscador"
                  v-model="searchText"
                  @input="searchUser"
                >
                <span class="icon is-right">
                  <span class="material-icons">search</span>
                </span>
              </div>
            </div>
          </div>
        </nav>
        <div class="columns is-multiline">
          <div class="column is-12 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="adminList"
                :loading=loading
              >
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
                <div class="is-flex is-justify-content-center is-align-items-center" slot="action" slot-scope="scope">
                  <img class="action_img" src="@/assets/icon/edit_icon_pen.png" alt="Editar" @click="showDetailAction(scope.row)">
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-12">
            <base-pagination
              :page="page"
              :pages="parseInt(pages)"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'AdminUsersMain',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue'),
    ModalAddAdminUser: () => import('@/components/AdminUsers/ModalAddAdminUser.vue'),
    ModalEditAdminUser: () => import('@/components/AdminUsers/ModalEditAdminUser.vue')
  },
  data () {
    return {
      loading: false,
      activeModalEditAdminUser: false,
      page: 1,
      pages: 1,
      columnsData: [
        { id: 'name', header: 'Nombre', accessor: (row) => `${row.first_name} ${row.last_name}` },
        { id: 'email', header: 'Correo electrónico' },
        { id: 'registrationDate', header: 'Fecha de registro', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'status', name: 'status', header: 'Estatus' },
        { id: 'action', name: 'action', header: 'Acciones' }
      ],
      adminList: [],
      searchText: '',
      adminDataSelected: {}
    }
  },
  props: {
    activeModalAddAdminUser: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(['getAdminUsers']),
    async getAdminList (page, search) {
      const resp = await this.getAdminUsers({ page: page, limit: DEFAULT_DISPLAY_SIZE, search: search })
      this.pages = resp.totalPages
      this.adminList = resp.users
      this.loading = false
    },
    searchUser ({ target }) {
      this.page = 1
      this.loading = true
      if (target.value.length >= 3 || target.value.length === 0) {
        this.searchText = target.value.length === 0 ? '' : target.value
        this.getAdminList(this.page, target.value)
      }
    },
    paginator (page) {
      this.loading = true
      this.page = page
      this.getAdminList(this.page, this.searchText)
    },
    successfulCreationUser () {
      this.page = 1
      this.getAdminList(this.page, this.searchText)
      this.$emit('close-modal')
    },
    showDetailAction (event) {
      this.adminDataSelected = event
      this.activeModalEditAdminUser = true
    },
    successfullEditUser () {
      this.getAdminList(this.page, this.searchText)
      this.activeModalEditAdminUser = false
    }
  },
  beforeMount () {
    this.loading = true
    this.getAdminList(this.page, this.searchText)
  }
}
</script>

<style lang="scss" scoped>
  .level-item {
    justify-content: flex-start;
  }

  .action_img {
    width: 15px;
    margin: auto 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .level-item > *, select {
      width: 100%;
    }
  }
</style>
